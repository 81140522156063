<template>
  <div class="start-when">
    <FDatePicker
      v-model="startDate"
      placeholder="Select date"
      :is-full-width="false"
      is-input-readonly
      :clearable="false"
      :disabled-date="disabledDate"
    />

    <div v-if="isCurrentDay" class="start-now">
      {{ t('merchantSubscriptions.subscriptionCreateModal.label.startNow') }}
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

defineProps({
  isCurrentDay: {
    type: Boolean,
    default: true,
  },
});

const startDate = defineModel({ type: String, default: '' });

const disabledDate = (date) => date < dayjs().subtract(1, 'day');
</script>

<style scoped lang="scss">
:deep(.f-date-picker) {
  margin-bottom: 0;
  width: 100%;
  .el-input {
    width: 100%;
  }
}

.start-when {
  position: relative;
}

.start-now {
  position: absolute;
  font-size: 13px;
  top: 5px;
  left: 36px;
  padding: 10px 2px;
  width: calc(100% - 42px);
  background: #fff;
  color: #999;
  pointer-events: none;
}
</style>
