<template>
  <SmoothModal
    :model-value="modelValue"
    no-overflow
    :has-header-divider="false"
    @update:model-value="closeModal"
  >
    <template #title>
      {{ t('merchantSubscriptions.subscriptionCreateModal.title') }}
    </template>
    <template #description>
      <AppText :line-height="1.35" color="var(--color-black-05)">
        {{ t('merchantSubscriptions.subscriptionCreateModal.subtitle') }}
        <ExternalLink
          style="display: inline-flex;"
          :has-prepend="false"
          has-primary-color
        >
          {{ t('common.learnMore') }}
        </ExternalLink>
      </AppText>
    </template>
    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="onCreateSubscription"
      @error="onError"
    >
      <template #default="{ errors, action }">
        <FInput
          v-model="requestFields.title"
          :placeholder="t('merchantSubscriptions.subscriptionCreateModal.label.titleMemo')"
          has-prefix
          maxlength="40"
          :validation-error="errors.title"
        >
          <template #prefix>
            <AppIcon
              name="doc-bill-invoice"
              size="18px"
              :opacity="0.4"
              is-img-tag
            />
          </template>
        </FInput>

        <SelectClient
          v-if="modelValue"
          :key="modelValue"
          v-model:email="requestFields.clientEmail"
          v-model:name="requestFields.clientName"
          v-model:status="clientStatus"
          v-model:is-new-client="isNewClient"
          :options="clients"
          :errors="errors"
          :response-errors="localizedErrors"
          style="margin-top: 20px;"
          @get-clients="onGetClientsFromEndpoint"
        />

        <AppText
          is-block
          type="uppercase"
          size="11px"
          class="font-medium"
          pb="10px"
          color="var(--color-black-04)"
        >
          {{ t('common.amount') }}
        </AppText>
        <AmountInput
          v-model="requestFields.amount"
          :error="errors.amount"
          :placeholder="minAmount"
          :number-format="numberFormat"
          :currency="currency.value"
        />

        <div class="d-flex" style="margin-top: 20px;">
          <div class="mr-4" style="flex: 1;">
            <FSelect
              v-model="requestFields.cycle"
              :placeholder="t('common.cycle')"
              has-arrow
              :options="cycleData"
              :has-error="Boolean(errors.cycle?.message)"
              :error-text="errors.cycle?.message"
            >
              <template #prefix>
                <AppIcon name="arrow-rotate-left" size="18px" :opacity="0.4" />
              </template>
            </FSelect>
          </div>

          <div style="flex: 1">
            <StartWhen
              v-model="requestFields.startDate"
              :is-current-day="isCurrentDay"
            />
          </div>
        </div>
        <AppText variant="div" mb="30px" color="var(--color-black-04)">
          <template v-if="isCurrentDay">
            {{ t('merchantSubscriptions.subscriptionCreateModal.label.beginsImmediatelly') }}
          </template>
          <i18n-t v-else keypath="merchantSubscriptions.subscriptionCreateModal.label.beginsLater">
            <template #date>
              <span>{{ requestFields.startDate }}</span>
            </template>
          </i18n-t>
        </AppText>

        <AppTooltip
          :is-enabled="isSelectedClientBlocked"
          text-align="left"
          width="252px"
        >
          <template #text>
            {{ t('merchantSubscriptions.subscriptionCreateModal.label.subscriptionCannotBeCreated') }}
          </template>

          <FButton
            no-margin
            :disabled="isSelectedClientBlocked"
            :loading="isLoading"
            @click="action"
          >
            {{ t('merchantSubscriptions.subscriptionCreateModal.title') }}

            <AppIcon
              v-if="isSelectedClientBlocked"
              name="info-circle-medium"
              :opacity="0.2"
              is-img-tag
              size="18px"
              style="margin-left: 8px;"
            />
          </FButton>
        </AppTooltip>
      </template>
    </ActionForm>
  </SmoothModal>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import SelectClient from '@/components/Payment/SelectClient';
import AmountInput from '@/components/Payment/AmountInput.vue';
import ExternalLink from '@/components/ExternalLink.vue';
import SmoothModal from '@/components/SmoothModal';
import AppTooltip from '@/components/AppTooltip.vue';

import { сreateClient } from '@/api/clients';
import { currencyList } from '@/common/data';
import { ActionForm, useValidation, yup } from '@/validation';
import {
  baseAmountRule,
  emailRule,
  requiredRule,
  stringWithoutSpaces,
} from '@/validation/rules';
import { currency } from '@/composables/useCurrency';
import { useSettings } from '@/composables/useSettings';
import { i18n } from '@/plugins/localization';
import { addThousandsSeparators } from '@/utils/numberFormatter';
import { useNotifications } from '@/composables/useNotifications';

import { cycleData as rawCycleData } from '../data';
import { subscriptionApi } from '../api';

import StartWhen from './StartWhen.vue';

export default defineComponent({
  components: {
    SelectClient,
    SmoothModal,
    StartWhen,
    ActionForm,
    ExternalLink,
    AmountInput,
    AppTooltip,
  },
  props: {
    modelValue: {
      type: Boolean,
      deafult: false,
    },
  },
  emits: ['update-data', 'update:modelValue'],
  setup(props, { emit }) {
    dayjs.extend(utc);
    const { dateFormat, numberFormat } = useSettings();

    const closeModal = () => {
      emit('update:modelValue');
      clientStatus.value = '';
    };

    const {
      isLoading,
      addSubscription,
      clients,
      onGetClients,
    } = subscriptionApi();

    const {
      initState, localizedErrors, fieldsErrors, clearState,
    } = useValidation();

    const fields = {
      title: '',
      amount: null,
      cycle: '',
      startDate: dayjs.utc().format(dateFormat.value),
      clientEmail: '',
      clientName: '',
    };

    const defaultCurrency = computed(() => currencyList.find((el) => el.short === currency.value.value));
    const minAmount = computed(() => addThousandsSeparators({
      val: defaultCurrency.value?.minAmount,
      separator: numberFormat.value,
    }));

    const validationSchema = yup.object().shape({
      clientEmail: emailRule,
      clientName: requiredRule,
      title: stringWithoutSpaces,
      amount: baseAmountRule(defaultCurrency.value?.minAmount, numberFormat.value, currency.value.sign),
      cycle: yup
        .string()
        .required('isRequired'),
    });

    const { requestFields } = initState(fields);

    const clientValidationSchema = yup.object().shape({
      clientEmail: emailRule,
      clientName: requiredRule,
    });

    const onError = (errors) => {
      fieldsErrors.value = errors;
      Object.assign(fieldsErrors.value, errors);
    };

    const isNewClient = ref(false);
    const clientStatus = ref('');
    const { addNotification } = useNotifications();
    const onCreateSubscription = async () => {
      let shouldSubscriptionBeCreated = false;
      if (isNewClient.value && !fieldsErrors.value.length) {
        const { isSuccess, errorObject } = await сreateClient({
          email: requestFields.clientEmail,
          name: requestFields.clientName,
        });
        if (isSuccess) {
          await onGetClients();
        }
        if (!isSuccess) {
          fieldsErrors.value = errorObject.fields;
        }
        shouldSubscriptionBeCreated = isSuccess;
      } else {
        shouldSubscriptionBeCreated = true;
      }
      if (shouldSubscriptionBeCreated) {
        const { data } = await onGetClients('');

        const selectedClient = data.find((el) => el.clientEmail === requestFields.clientEmail);

        if (selectedClient.status === 'DO_NOT_DISTURB') {
          addNotification({ text: t('errors.clientHasDndEnabled'), config: { color: 'error' } });
          isLoading.value = false;
          clientStatus.value = 'DO_NOT_DISTURB';
        } else {
          const { isSuccess, errorObject } = await addSubscription(requestFields);
          if (isSuccess) {
            emit('update-data');
            clearState();
            closeModal();
          } else {
            onError(errorObject.fields);
          }
        }
      }
    };

    watch(computed(() => props.modelValue), () => {
      clearState();
      clientStatus.value = '';
    });

    const updateClientErrors = () => {
      delete fieldsErrors.value.clientName;
    };

    const isCurrentDay = computed(() => {
      const selectedDate = dayjs(requestFields.startDate, dateFormat.value);

      const currentDate = dayjs(dayjs().format(dateFormat.value), dateFormat.value).startOf('day');
      const diff = selectedDate.diff(currentDate, 'day');

      return diff === 0;
    });

    const isSelectedClientBlocked = computed(() => {
      const client = clients.value.find((item) => item.value === requestFields.clientEmail);
      return ['DO_NOT_DISTURB', 'BLOCKED'].includes(client?.status) || false;
    });

    const onGetClientsFromEndpoint = async (val) => {
      await onGetClients(val);
    };

    const { t } = i18n.global;

    const cycleData = computed(() => rawCycleData.map((cycle) => ({
      text: t(`cycle.${cycle.value}`),
      value: cycle.value,
    })));

    return {
      closeModal,

      cycleData,
      isLoading,
      clients,

      fieldsErrors,

      currency,
      minAmount,

      requestFields,
      clientStatus,
      validationSchema,
      onCreateSubscription,

      clientValidationSchema,
      localizedErrors,
      isNewClient,
      onError,
      dateFormat,
      numberFormat,
      isCurrentDay,
      isSelectedClientBlocked,

      updateClientErrors,

      onGetClientsFromEndpoint,
    };
  },
});

</script>
