<template>
  <TransitionWrapper>
    <div v-if="!isPlanFree" class="main-content-body">
      <FiltersHeader
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        :statuses="statuses"
        :filters="filters"
      >
        <template #top-append>
          <AddButton
            :is-disabled="!isWalletConnected"
            :disabled-tooltip="t('merchantSubscriptions.subscriptionCreateModal.label.disabledTooltip')"
            @click="isSubscriptionCreateModalOpen = isWalletConnected"
          >
            {{ t('merchantSubscriptions.subscriptionCreateModal.title') }}
          </AddButton>
        </template>
      </FiltersHeader>

      <PaginationTable
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        @row-click="onRowClick"
      />
    </div>

    <LockSubscriptionTable v-else />

    <CreateSubscriptionModal
      v-model="isSubscriptionCreateModalOpen"
      @update-data="onCreateSubscription"
    />

    <SubscriptionModal
      v-model="isSubscriptionModalOpen"
      :subscription="selectedSubscription"
      @update-data="onUpdateData"
    />
  </TransitionWrapper>
</template>

<script>
import { ref } from 'vue';

import AddButton from '@/components/Buttons/AddButton.vue';
import FiltersHeader from '@/components/Modules/DataTable/FiltersHeader.vue';
import PaginationTable from '@/components/Modules/DataTable/PaginationTable.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';
import { MODULES_NAMES } from '@/common/modules';
import { subscribeToUpdateEmmiter, usePagination } from '@/composables/usePagination';
import { useWallet } from '@/composables/useWallet';
import { useUser } from '@/composables/useUser';
import { useTablesStore } from '@/store';

import CreateSubscriptionModal from './CreateSubscriptionModal';
import LockSubscriptionTable from './components/LockSubscriptionTable.vue';
import SubscriptionModal from './SubscriptionModal';
import { columns, filters, statuses } from './data';
import { subscriptionApi } from './api';

export default {
  name: 'MerchantSubscriptions',
  components: {
    CreateSubscriptionModal,
    SubscriptionModal,
    FiltersHeader,
    PaginationTable,
    TransitionWrapper,
    LockSubscriptionTable,
    AddButton,
  },
  setup() {
    const moduleName = MODULES_NAMES.MERCHANT_SUBSCRIPTIONS;
    const apiPath = '/merchants/clients/subscriptions/elastic';

    const { getData, items } = usePagination(apiPath, { moduleName, filters });
    const { onGetClients } = subscriptionApi();

    getData();
    onGetClients();

    const isSubscriptionCreateModalOpen = ref(false);
    const isSubscriptionModalOpen = ref(false);

    const selectedSubscription = ref({});

    const onRowClick = (row) => {
      selectedSubscription.value = row;
      isSubscriptionModalOpen.value = true;
    };

    const onUpdateData = async () => {
      await getData({ isFreshResponse: true });

      const subsctiption = items.value.find((sub) => sub.id === selectedSubscription.value.id);
      if (subsctiption) {
        selectedSubscription.value = subsctiption;
      }
    };

    const tableStore = useTablesStore();
    const onCreateSubscription = () => {
      tableStore.resetPageDateFilters(moduleName);
      setTimeout(() => {
        getData({ isFreshResponse: true });
        isSubscriptionCreateModalOpen.value = false;
      }, 0);
    };

    const { isPlanFree } = useUser();
    const { isWalletConnected } = useWallet();

    useKeyboardEvent(keys.BACKQUOTE, () => { isSubscriptionCreateModalOpen.value = isWalletConnected.value; });

    subscribeToUpdateEmmiter('update-subscriptions-page', getData, onGetClients);

    return {
      moduleName,
      apiPath,

      columns,
      statuses,
      filters,

      onUpdateData,

      isPlanFree,
      isWalletConnected,

      selectedSubscription,

      isSubscriptionCreateModalOpen,
      isSubscriptionModalOpen,

      onRowClick,
      onCreateSubscription,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.table__body .table__row__cell) {
  cursor: pointer;
}
</style>
