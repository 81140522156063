<template>
  <div class="cancel-wrapper">
    <AppIcon name="warning-banner" size="60px" />
    <AppText
      variant="div"
      size="21px"
      class="font-medium"
      align="center"
      style="max-width: 480px;"
      mt="20px"
    >
      {{ t('merchantSubscriptions.subscriptionInfoModal.label.cancelTitle') }}
    </AppText>
    <AppText
      variant="div"
      size="15px"
      :line-height="1.5"
      :opacity="0.6"
      style="max-width: 420px;"
      align="center"
      mt="30px"
      mb="30px"
    >
      {{ t('merchantSubscriptions.subscriptionInfoModal.label.cancelDescription') }}
    </AppText>

    <div class="d-flex" style="gap: 8px;">
      <FButton
        class="font-medium"
        type="danger"
        no-margin
        :loading="isLoading"
        @click="onCancel"
      >
        <AppText class="font-medium">
          {{ t('merchantSubscriptions.subscriptionInfoModal.button.cancel') }}
        </AppText>
      </FButton>
      <FButton
        class="font-medium"
        type="plain"
        no-margin
        @click="onKeep"
      >
        <AppText class="font-medium">
          {{ t('merchantSubscriptions.subscriptionInfoModal.button.keep') }}
        </AppText>
      </FButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CancelSubscription',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove', 'keep'],
  setup(props, { emit }) {
    const onCancel = () => {
      emit('remove');
    };

    const onKeep = () => {
      emit('keep');
    };

    return {
      onCancel,
      onKeep,
    };
  },
});
</script>

<style scoped lang="scss">
.cancel-wrapper {
  padding: 150px 60px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
